@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-default{
    @apply bg-green-500 text-white font-bold py-2 px-4 rounded;
  }
  .btn-error{
    @apply bg-red-500 text-white font-bold py-2 px-4 rounded;
  }
}